@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

/* VISUAL COMPOSER */

.wpb_content_element {
    margin-bottom: -7px;
}
.vc_col-has-fill > .vc_column-inner,
.vc_row-has-fill+.vc_row-full-width + .vc_row > .vc_column_container > .vc_column-inner {
	padding-top: 0;
}
.container {
	max-width: 1600px;
	padding: 0 50px;
}

/* BODY */

body {
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
}

.wrap {
	margin-top: 180px;
}

/* HEADER */

header {
	position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
	background-color: #161A29;
	padding: 35px 0 15px 0;
	.flex {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
	.flex > * {
		flex-grow: 1;
		flex-basis: 0;
	}
	hr {
		max-width: 500px;
		height: 1px;
		background: rgba(255,255,255,0.7);
	}
	.left {
		nav {
			margin-bottom: 15px;
		}
		nav a {
			font-size: 14px;
			text-transform: uppercase;
			color: white;
			margin-right: 35px;
			margin-top: 30px;
			transition: all 0.3s;
		}
		nav li.active a,
		nav a:hover {
			color: #DA592F;
			text-decoration: none;
		}
	}
	.center {
		text-align: center;
		img {
			margin-bottom: 30px; 
		}
	}
	.right {
		text-align: right;
		p {
			display: inline-block;
			color: white;
			font-size: 14px;
			margin-right: 15px;
		}
	}
}
header:after {
    content: "";
    height: 11px;
    width: 11px;
    background: white;
    display: block;
    border: 20px solid #161A29;
    border-radius: 100px;
    box-sizing: content-box;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,50%);
    z-index: 100;
}

header a.btn {
	font-size: 12px;
	margin-left: 5px;
	margin-bottom: 5px;
}

/* BOUTON */

a.btn {
	padding: 5px;
	background-color: #DA592F;
	color: white;
	border-radius: 3px;
	text-transform: uppercase;
	font-size: 14px;
	transition: all 0.3s;
	min-width: 121px;
	span {
		padding: 10px 15px;
		border: 1px dashed white;
		border-radius : 3px;
		display: block;
	}
}
a.btn:hover {
	background-color: #c04922;
}

a.btn-white {
	background-color: white;
	color: #DA592F;
	span {
		border: 1px dashed #DA592F;
	}
}

a.btn-white:hover {
	background-color: #F8F8F8;
}

/* TITRE */

.title {
	text-align: center;
}

.title.style_white h3 {
	position: relative;
	display: inline-block;
	color: white;
	font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;
}
.title.style_white p {
	font-size: 15px;
	text-transform: initial;
	font-weight: 400;
	color: white;
}
.title.style_white h3:before,
.title.style_white h3:after {
	content: "";
    height: 6px;
    width: 6px;
    border-radius: 6px;
    background: white;
    position: absolute;
    top: 6px;
}
.title.style_white h3:before {
	left: -20px;
}
.title.style_white h3:after {
	right: -20px;
}

.title.style_black h3 {
	position: relative;
	display: inline-block;
	color: black;
	font-size: 30px;
	text-transform: uppercase;
	line-height: 30px;
	font-weight: 700;
	span {
		font-size: 22px;
		text-transform: initial;
		font-weight: 400;
	}
}
.title.style_black h3:after {
	content: "";
	display: block;
	height: 2px;
	width: 160px;
	background-color: #DA592F;
	position: absolute;
	bottom: -45px;
	left: 50%;
    transform: translateX(-50%); 
}

/* FOOTER */

footer {
	background-color: #161A29;
	padding: 60px 0 25px 0;
	h3 {
		color: #DA592F;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
	}
	a, p {
		font-size: 14px;
		color: white;
		text-decoration: none;
		display: block;
	}
	a:hover {
		color: white;
	}
	.bottom {
		margin-top: 40px;
		border-top: 1px dashed white;
		padding-top: 15px;
		a {
			text-decoration: underline;
			display: inline-block;
		}
	}
}

@media(max-width:425px) {
	.wrap {
		margin-top: 0;
	}
	header {
		position: relative;
	}
	body {font-size: 15px;}
	.container {
		padding: 0 15px;
	}
	header .flex,
	.nav {
		display: block;
		text-align: center;
	}
	.nav li {
		display: inline-block;
		a {
			margin-right: 0 !important;
		}
	}
	header .right {
	    text-align: center;
	    margin-bottom: 20px;
	}
	ul.nav >li:first-child a {
		margin-right: 30px !important;
	}
	.title.style_white h3,
	.title.style_black h3 {
		font-size: 25px;
		line-height: 25px;
	}
	.title.style_black h3 span {
    	font-size: 20px;
    	line-height: 22px !important;
    	display: inline-block;
    	margin-bottom: 10px;
	}
	footer {
		text-align: center;
	}
	footer h3 {
		margin-top: 20px;
	}
}